<template>
  <v-card
    :ripple="{ class: 'primary--text' }"
    class="provider-card"
    data-cy="link-providers-breakdown"
    @click="$emit('goto-provider-detail', providerData.canonical)">
    <template v-if="!loading">
      <v-card-title class="provider-card__title d-flex px-4 justify-space-between">
        {{ _.get(_.find(getProviderExtraInfo, ['canonical', providerData.canonical]), 'displayName') }}
      </v-card-title>
      <v-card-text class="px-0">
        <div class="d-flex px-4">
          <div
            v-for="dataType in ['cost', 'co2e', 'kwh']"
            :key="dataType"
            class="mr-6">
            <div class="item-title d-flex align-center">
              <v-badge
                class="mr-1"
                :color="$static.chartColors[dataType]"
                bordered
                dot
                inline
                left/>
              <span
                v-html="$sanitizeHtml(getUnitSymbolByType(dataType))"/>
            </div>
            <h4 class="item-value ml-5">
              {{ formatAmount(providerData[dataType]) }}
            </h4>
            <CyCloudCostManagementTrendChip
              class="ml-4"
              :value="[_.get(_.head(series), dataType, 0), _.get(_.last(series), dataType, 0)]"/>
          </div>
        </div>

        <div
          v-if="!_.isEmpty(providerData.costData)"
          class="provider-card__graph">
          <ECharts
            class="line-chart"
            theme="cycloid"
            autoresize
            :option="options"/>
        </div>
      </v-card-text>
      <v-card-actions class="provider-card__actions d-flex justify-space-between font-weight-normal pl-6 pr-2">
        <span class="provider-detail">
          {{ $t('cloudCostManagement.explore') }}
          {{ _.get(_.find(getProviderExtraInfo, ['canonical', providerData.canonical]), 'displayName') }}
        </span>
        <v-icon>
          chevron_right
        </v-icon>
      </v-card-actions>
    </template>
    <div
      v-else
      class="sk-template d-flex flex-column">
      <v-card-title class="provider-card__title d-flex justify-space-between flex-grow-1 px-4 pb-0">
        <span class="d-flex align-center">
          <div class="sk-block sk-avatar mr-2"/>
          <div class="sk-block sk-title sk-dark"/>
        </span>
        <div class="sk-block sk-title sk-dark"/>
      </v-card-title>
      <v-card-text class="px-6">
        <div class="sk-block sk-img flex-grow-1 my-4"/>
        <div class="sk-block sk-title sk-dark sk-w-20 mb-1"/>
        <div class="sk-block sk-title sk-w-36"/>
      </v-card-text>
      <v-card-actions class="provider-card__actions d-flex justify-space-between px-6">
        <div class="sk-block sk-title sk-w-20"/>
        <div class="sk-block sk-avatar mr-n2"/>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CyCloudCostManagementTrendChip from '@/components/cloud-cost-management/trend-chip.vue'
import { formatAmount, getCurrencySymbol } from '@/utils/helpers'
import { dataTypeColors } from '@/utils/helpers/cloud-cost-management'

export default {
  name: 'CyCloudCostManagementProviderCard',
  components: {
    CyCloudCostManagementTrendChip,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    providerData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    $static: () => ({
      chartColors: dataTypeColors,
    }),
    ...mapGetters('organization/cloudCostManagement', [
      'getProviderExtraInfo',
    ]),
    ...mapState('organization/cloudCostManagement', {
      currency: (state) => state.queryBody.currency,
      histogramDates: (state) => state.histogramDates,
    }),
    series () {
      return this.histogramDates.map((timestamp) => {
        const date = this.formatDate(timestamp)
        const cost = _.find(this.providerData.costData, ['date', timestamp])?.cost || 0
        const co2e = _.find(this.providerData.co2eData, ['date', timestamp])?.co2e || 0
        const kwh = _.find(this.providerData.kwhData, ['date', timestamp])?.kwh || 0
        return { date, cost, co2e, kwh }
      })
    },
    options () {
      return {
        grid: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          containLabel: false,
        },
        series: ['cost', 'co2e', 'kwh'].map((type) => ({
          areaStyle: {
            opacity: 0,
          },
          lineStyle: {
            color: this.$static.chartColors[type],
          },
          data: this.series.map((it) => [it.date, it[type]]),
          symbol: 'none',
          type: 'line',
        })),
        tooltip: {
          trigger: 'axis',
          formatter: this.formatTooltip,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: _.map(this.histogramDates, (timestamp) => this.formatDate(timestamp)),
        },
        yAxis: {
          splitLine: {
            show: false,
          },
          type: 'value',
        },
      }
    },
  },
  methods: {
    getCurrencySymbol,
    formatAmount,
    formatDate (date) {
      return $date.format(new Date(date), 'MMM d, yyyy')
    },
    formatTooltip ([{ data: [date, cost] }, { data: [_, co2e] }, { data: [__, kwh] }]) {
      const { currency } = this
      return `
        <p class='mb-1'>${date}</p>
        <ul class='pl-0'>
          <li>
            <span class="marker" style="background-color:${this.$static.chartColors.cost}"></span>
            <span class="ml-n1">${this.$t('cloudCostManagement.cost')}:</span>
            <strong>${this.formatAmount(cost)}</strong>
            <span>${this.getCurrencySymbol(currency)}</span>
          </li>
          <li>
            <span class="marker" style="background-color:${this.$static.chartColors.co2e}"></span>
            <span class="ml-n1">${this.$t('cloudCostManagement.emissions')}:</span>
            <strong>${this.formatAmount(co2e)}</strong>
            <span>${this.$t('untranslated.co2e')}</span>
          </li>
          <li>
            <span class="marker" style="background-color:${this.$static.chartColors.kwh}"></span>
            <span class="ml-n1">${this.$t('cloudCostManagement.energy')}:</span>
            <strong>${this.formatAmount(kwh)}</strong>
            <span>${this.$t('untranslated.kwh')}</span>
        </ul>
      `
    },
    getUnitSymbolByType (dataType) {
      if (dataType === 'cost') return `${this.$t('cloudCostManagement.cost')} (${this.getCurrencySymbol(this.currency)})`
      return this.$t(`untranslated.${dataType}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.provider-card {
  overflow: hidden;
  transition: box-shadow 0.2s ease-out;
  border-radius: 8px;
  font-weight: $font-weight-bolder;

  &__title {
    font-family: $font-family-default;
    font-size: $font-size-lg;
    font-weight: $font-weight-extra-bold;
  }

  .item-title {
    color: cy-get-color("primary", "light-2");
    font-size: $font-size-sm;
  }

  .item-value {
    font-family: $font-family-condensed;
    font-size: $font-size-lg;
  }

  .trend-chip {
    font-size: $font-size-sm;
  }

  &__actions {
    height: 56px;
    border-top: solid 1px cy-get-color("grey", "light-1");

    .provider-detail {
      transition: color 0.2s ease;
      color: cy-get-color("grey", "dark-2");
    }

    .v-icon {
      position: relative;
      transition: color 0.2s ease, transform 0.2s ease;
      color: cy-get-color("grey", "dark-1");
    }
  }

  &__graph {
    height: 92px;
    margin-top: 36px;

    .line-chart {
      width: 100%;
      height: 92px;

      ::v-deep {
        > div {
          cursor: pointer !important;
        }

        .marker {
          display: inline-block;
          position: relative;
          top: 1px;
          width: 10px;
          height: 10px;
          margin-right: 5px;
          border: solid 1px white;
          border-radius: 10px;
        }

        ul {
          list-style-type: none;
        }
      }
    }
  }

  &:hover,
  &:focus {
    box-shadow: cy-get-box-shadow(card);
    cursor: pointer;

    .provider-card__actions {
      .provider-detail,
      .v-icon {
        color: cy-get-color("primary");
      }

      .v-icon {
        transform: translateX(4px);
      }
    }
  }
}
</style>
