<template>
  <div class="infinite-scrolling d-flex w-100">
    <slot v-if="loading">
      <v-progress-circular
        class="ma-auto"
        color="secondary"
        indeterminate
        size="20"/>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'CyInfiniteScrolling',
  props: {
    stopLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    intervalId: null,
    loading: true,
    observer: null,
  }),
  computed: {
    $static: () => ({
      options: {
        rootMargin: '100px 0px 500px 0px',
        thresholds: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      },
    }),
  },
  mounted () {
    this.intervalId = window.setInterval(this.checkScrollingStatus, 500)
    this.observer = new IntersectionObserver(this.scrollMore, this.$static.options)
    this.observer.observe(this.$el)
  },
  methods: {
    scrollMore ([{ isIntersecting } = {}]) {
      if (isIntersecting && !this.stopLoading) {
        this.$emit('load-more')
      }
    },
    checkScrollingStatus () {
      if (this.stopLoading) window.clearInterval(this.intervalId)

      const { top, left, right, bottom } = this.$el.getBoundingClientRect()
      const isElementInViewport = top >= 0 && left >= 0 && right <= window.innerWidth && bottom <= window.innerHeight

      if (isElementInViewport) {
        this.$emit('load-more')
      }
    },
  },
}
</script>
