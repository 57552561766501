<template>
  <div class="mt-n4 pb-2">
    <CyRandomTip
      v-if="!importingAccountsCount && !erroredAccountsCount"
      class="my-4"
      :ls-path="lsPath"
      :tips="dashboardTips"/>
    <CyAlert
      v-if="importingAccountsCount"
      closeable
      class="my-4"
      theme="warning"
      :title="$tc('importingAccounts.title', importingAccountsCount, { nb: importingAccountsCount })"
      :content="$tc('importingAccounts.message', importingAccountsCount, { nb: importingAccountsCount })"
      @close="closeImportingNotification"/>
    <CyAlert
      v-if="erroredAccountsCount"
      closeable
      class="my-4"
      theme="error"
      :title="$t('erroredAccounts.title')"
      :content="$t('erroredAccounts.message')"
      @close="closeErroredNotification"/>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CyRandomTip from '@/components/random-tip.vue'

export default {
  name: 'CyCloudCostManagementDashboardNotifications',
  components: {
    CyRandomTip,
  },
  data: () => ({
    importingAccountsCount: 0,
    erroredAccountsCount: 0,
  }),
  computed: {
    ...mapGetters('organization/cloudCostManagement', [
      'getImportingAccounts',
      'getErroredAccounts',
    ]),
    ...mapState('organization/cloudCostManagement', {
      accounts: (state) => state.accounts,
    }),
    $static: () => ({
      tips: ['currency', 'decimals'],
    }),
    dashboardTips () {
      return _.map(this.$static.tips, (tip) => ({
        title: _.capitalize(this.$t(`${tip}.title`)),
        content: this.$t(`${tip}.message`),
      }))
    },
    lsPath () {
      return LSK.CCM_DASHBOARD_NOTIFICATIONS
    },
  },
  watch: {
    accounts: {
      handler () {
        this.setAccountStatuses()
      },
      deep: true,
    },
  },
  mounted () {
    this.setAccountStatuses()
  },
  methods: {
    setAccountStatuses () {
      const importingAccounts = this.getImportingAccounts
      const erroredAccounts = this.getErroredAccounts

      const previousImported = JSON.parse(localStorage.getItem(LSK.CCM_DISMISSED_ACCOUNT_IMPORT) || '{}')
      const previousErrored = JSON.parse(localStorage.getItem(LSK.CCM_DISMISSED_ACCOUNT_ERROR) || '{}')

      if (_.some(importingAccounts, ({ account_id: id, last_ingestion_started_at: lastIngestionAt }) => previousImported[id] !== lastIngestionAt)) {
        this.importingAccountsCount = importingAccounts.length
      } else {
        this.importingAccountsCount = 0
      }

      if (_.some(erroredAccounts, ({ account_id: id, last_ingestion_started_at: lastIngestionAt }) => previousErrored[id] !== lastIngestionAt)) {
        this.erroredAccountsCount = erroredAccounts.length
      } else {
        this.erroredAccountsCount = 0
      }
    },
    closeImportingNotification () {
      this.importingAccountsCount = 0
      const dismissedAccounts = this.getImportingAccounts
        .reduce((acc, { account_id: id, last_ingestion_started_at: lastIngestionAt }) => ({ ...acc, [id]: lastIngestionAt }), {})
      localStorage.setItem(LSK.CCM_DISMISSED_ACCOUNT_IMPORT, JSON.stringify(dismissedAccounts))
    },
    closeErroredNotification () {
      this.erroredAccountsCount = 0
      const dismissedAccounts = this.getErroredAccounts
        .reduce((acc, { account_id: id, last_ingestion_started_at: lastIngestionAt }) => ({ ...acc, [id]: lastIngestionAt }), {})
      localStorage.setItem(LSK.CCM_DISMISSED_ACCOUNT_ERROR, JSON.stringify(dismissedAccounts))
    },
  },
  i18n: {
    messages: {
      en: {
        importingAccounts: {
          message: 'While the import is in progress you may experience either incomplete data appearance or no data at all.',
          title: '1 account import is in progress! | {nb} account imports are in progress!',
        },
        erroredAccounts: {
          message: 'Due to the error type, the Cloud Cost Management cannot identify nor assume the precise cause of failure. Go to account configuration to review your current configuration.',
          title: 'Account creation failure!',
        },
        currency: {
          message: 'Due to the different cloud provider default currency displays, Cycloid made a conversion to show the same currency for each.',
          title: 'Currency conversion',
        },
        decimals: {
          message: 'To make reports legible, numbers are displayed with up to 2 decimals while the maximum precision is preserved under the hood. Costs below 1 cent are displayed as "<€0.01".',
          title: 'Cloud costs may be expressed with up to 15 decimals',
        },
      },
      es: {
        importingAccounts: {
          message: 'Mientras la importación está en curso, es posible que experimente una apariencia de datos incompletos o que no tenga ningún dato.',
          title: '¡Se está realizando la importación de 1 cuenta! | ¡Se están realizando {nb} importaciones de cuentas!',
        },
        erroredAccounts: {
          message: 'Debido al tipo de error, Cloud Cost Management no puede identificar ni asumir la causa precisa de la falla. Vaya a la configuración de la cuenta para revisar su configuración actual.',
          title: '¡Error en la creación de la cuenta!',
        },
        currency: {
          message: 'Debido a los diferentes tipos de monedas predeterminadas de los proveedores de la nube configurados, Cycloid realizará una conversión para hacer si que se mostren todas en la misma divisa.',
          title: 'Conversión de divisa',
        },
        decimals: {
          message: 'Para que los informes sean legibles, los números se muestran con hasta 2 decimales mientras se conserva la máxima precisión bajo el capó. Los costes inferiores a 1 céntimo se muestran como "<€0,01".',
          title: 'Los costos de la nube se pueden expresar con hasta 15 decimales',
        },
      },
      fr: {
        importingAccounts: {
          message: `Pendant que l'importation est en cours, vous pouvez recevoir des données incomplètes ou pas de données du tout.`,
          title: `Importation d'un compte en cours! | Importation de {nb} comptes en cours!`,
        },
        erroredAccounts: {
          message: `En raison du type d'erreur, Cloud Cost Management ne peut pas identifier ni supposer la cause précise de l'échec. Accédez à la configuration du compte pour revoir votre configuration actuelle.`,
          title: 'Echec de création de compte !',
        },
        currency: {
          message: 'En raison des différents types de devises par défaut des fournisseurs de cloud configurés, Cycloid a effectué une conversion afin de pouvoir afficher une seule et même devise.',
          title: 'Conversion de devises',
        },
        decimals: {
          message: `Pour rendre les rapports lisibles, les chiffres sont affichés avec jusqu'à 2 décimales tandis que la précision maximale est conservée en arrière plan. Les coûts inférieurs à 1 centime sont affichés comme "<€0,01".`,
          title: `Les coûts du cloud peuvent être exprimés avec jusqu'à 15 décimales`,
        },
      },
    },
  },
}
</script>
