<template>
  <div
    :class="['filters-sidebar space-y-4', {
      'filters-sidebar--collapsed': isSidebarCollapsed,
      'filters-sidebar--with-transition': hasSidebarTransition,
    }]">
    <CyCloudCostManagementSidebarToggle @sidebar-collapse="isSidebarCollapsed = $event"/>
    <div
      v-show="!isSidebarCollapsed"
      class="space-y-4">
      <CyCloudCostManagementSidebarDateRangeFilter @input="$emit('change')"/>
      <CyCloudCostManagementSidebarGranularitySelector @input="$emit('change')"/>
      <CyCloudCostManagementSidebarFilterList
        :filters="$static.filters"
        @input="$emit('change')"/>
      <CyCloudCostManagementSidebarCurrencySwitcher @input="$emit('change')"/>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import CyCloudCostManagementSidebarCurrencySwitcher from '@/components/cloud-cost-management/sidebar/currency-switcher.vue'
import CyCloudCostManagementSidebarDateRangeFilter from '@/components/cloud-cost-management/sidebar/date-range-filter.vue'
import CyCloudCostManagementSidebarFilterList from '@/components/cloud-cost-management/sidebar/filter-list.vue'
import CyCloudCostManagementSidebarGranularitySelector from '@/components/cloud-cost-management/sidebar/granularity-selector.vue'
import CyCloudCostManagementSidebarToggle from '@/components/cloud-cost-management/sidebar/toggle.vue'

export default {
  name: 'CyCloudCostManagementDashboardFiltersSidebar',
  components: {
    CyCloudCostManagementSidebarDateRangeFilter,
    CyCloudCostManagementSidebarGranularitySelector,
    CyCloudCostManagementSidebarFilterList,
    CyCloudCostManagementSidebarCurrencySwitcher,
    CyCloudCostManagementSidebarToggle,
  },
  data: () => ({
    hasSidebarTransition: false,
    isSidebarCollapsed: false,
  }),
  computed: {
    $static: () => ({
      filters: [
        'master_accounts',
        'linked_accounts',
        'projects',
      ],
    }),
  },
  created () {
    this.RESET_QUERY_BODY('dashboard')
  },
  mounted () {
    setTimeout(() => { this.hasSidebarTransition = true }, 1000)
  },
  methods: {
    ...mapMutations('organization/cloudCostManagement', [
      'RESET_QUERY_BODY',
    ]),
  },
}
</script>

<style lang="scss" scoped>
  .filters-sidebar {
    width: 328px;

    &--collapsed {
      width: 46px !important;
    }

    &--with-transition {
      transition: width 0.5s ease-in-out;
    }
  }
</style>
